import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import moneyFormat from "utils/moneyFormat";
import { LoanAPI } from "api";
import {
  ChevronLeftIcon,
  CurrencyDollarIcon,
  Document2Icon,
  InformationIcon,
  Refresh3Icon,
} from "assets/icons_v3/fonts";
import MoneySVG from "assets/icons_v3/money.svg";

import PaymentSkeleton from "components/Skeleton/Payment";
import Payment from "components/Payment";
import Button from "components/Button";
import BalanceModal from "components/Modals/Balance";
import { STATES_AVAILABLES } from "constants";
import formatDateCapitalize from "utils/formatDateCapitalize";
export default function KiwiPayActiveDisbursement() {
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const {
    products: {
      kiwi_pay: {
        loan: {
          id,
          balance,
          daysPastDue,
          amountDue,
          nextPaymentDate,
          nextPaymentAmount,
        },
      },
    },
  } = userState;

  const isLoanPaid = balance === 0;
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [balanceModalVisible, setBalanceModalVisible] = useState(false);

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = async () => {
    try {
      const {
        data: { data },
      } = await LoanAPI.getPayments(id);

      setPayments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onBackward = () => {
    navigate("/home");
  };

  const onPayFee = () => {
    navigate("/kiwi-pay/active/pay-fee");
  };

  const onHistoryPayment = () => {
    navigate("/kiwi-pay/active/history-payment");
  };

  const onPaymentDate = () => {
    navigate("/kiwi-pay/active/move-payment-day");
  };

  const onLoanDisbursed = () => {
    navigate("/kiwi-pay/active/loans-disbursed");
  };

  const onToggleBalanceModal = () => {
    setBalanceModalVisible((visble) => !visble);
  };

  return (
    <div className="absolute z-10 top-0 left-0 right-0 bottom-0 flex flex-col">
      <BalanceModal
        isVisible={balanceModalVisible}
        closeModal={onToggleBalanceModal}
      />

      <div>
        <div className="px-6 pt-10 pb-6 text-white bg-regal-blue-kiwi rounded-b-2xl">
          <div className="flex items-center justify-between mb-10">
            <ChevronLeftIcon
              className="w-2 cursor-pointer"
              onClick={onBackward}
            />
          </div>

          <div className="font-semibold text-2xl mb-6">KiwiPay</div>

          <div className="mb-8 text-sm">
            <div className="flex items-center justify-between mb-2 ">
              <div className="opacity-50">Próximo pago</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="font-semibold text-4xl">
                ${moneyFormat(nextPaymentAmount)}
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div>
                <span className="opacity-50">Fecha de pago:</span>{" "}
                <strong>
                  {formatDateCapitalize(nextPaymentDate)}
                </strong>
              </div>
            </div>
          </div>

          <div className="flex justify-around">
            <button
              onClick={onLoanDisbursed}
              className="flex flex-col items-center cursor-pointer"
            >
              <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                <Document2Icon className="w-6 h-6 text-white m-auto" />
              </div>
              <div className="text-xs mt-2 w-20 text-center">
                Resumen de préstamo
              </div>
            </button>
            {userState.user.state === STATES_AVAILABLES.puertoRico && (
              <button
                disabled={isLoanPaid}
                onClick={onPaymentDate}
                className="flex flex-col items-center cursor-pointer"
              >
                <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                  <Refresh3Icon className="w-6 h-6 text-white m-auto" />
                </div>
                <div className="text-xs mt-2 w-20 text-center">
                  Mover fecha de pago
                </div>
              </button>
            )}
            <button
              disabled={isLoanPaid}
              className="flex flex-col items-center cursor-pointer"
              onClick={onPayFee}
            >
              <div className="flex h-12 w-12 rounded-xl bg-white bg-opacity-20">
                <CurrencyDollarIcon className="w-6 h-6 text-white m-auto" />
              </div>
              <div className="text-xs mt-2 w-20 text-center">
                Pagar <br />
                cuota
              </div>
            </button>
          </div>
        </div>

        {isLoanPaid === false && daysPastDue > 0 && (
          <div
            className={`absolute flex items-center z-[-1] -mt-4 left-0 right-0 rounded-b-2xl px-6 pb-3 pt-7  text-xs font-medium bg-yellow-50-kiwi text-yellow-900-kiwi`}
          >
            <InformationIcon className="w-4 mr-2" />
            Días de mora:
            <strong className="ml-1">
              {daysPastDue} {daysPastDue > 1 ? "días" : "día"} | ${amountDue}
            </strong>
            <div
              className="cursor-pointer ml-auto font-semibold text-yellow-kiwi"
              onClick={onPayFee}
            >
              Pagar ahora
            </div>
          </div>
        )}
      </div>

      <div
        className={`mb-6 mx-6 flex flex-col gap-6 flex-auto mt-6`}
      >
        {isLoanPaid && (
          <div className="bg-blue-50-kiwi p-4 rounded-2xl flex items-start border border-blue-kiwi">
            <img src={MoneySVG} className="mr-4" alt="Money Kiwi" />
            <div className="text-xs mr-4">
              <div className="font-semibold">Nueva solicitud de préstamo</div>
              Puedes solicitar nuevamente dentro de{" "}
              <span className="font-semibold">5 días</span> de su ultima fecha
              de pago
            </div>
          </div>
        )}

        <div>
          <div className="flex justify-between items-center mb-6">
            <p className="font-semibold">Actividad reciente</p>

            {payments.length > 0 && (
              <button
                className="text-blue-kiwi text-xs font-medium"
                onClick={onHistoryPayment}
              >
                Ver todos
              </button>
            )}
          </div>

          <div className="flex flex-col gap-4">
            {loading === true ? (
              <>
                {[...Array(3)].map((item, index) => (
                  <PaymentSkeleton key={index} />
                ))}
              </>
            ) : (
              <>
                {payments.length === 0 ? (
                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 shrink-0 rounded-full bg-slate-100-kiwi flex">
                      <InformationIcon className="w-6 m-auto text-regal-blue-kiwi" />
                    </div>
                    <div className="mr-4">
                      <div className="text-sm">No tienes pagos realizados</div>
                      <div className="text-slate-500-kiwi text-xs">
                        Las actividades más recientes de tu cuenta aparecerán
                        aquí
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {payments.slice(0, 3).map((item, index) => (
                      <Payment payment={item} key={index} />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {isLoanPaid === false && loading === false && (
          <Button
            className="bg-blue-kiwi text-white rounded-xl p-3 w-full mt-auto"
            onClick={onPayFee}
          >
            Pagar cuota
          </Button>
        )}
      </div>
    </div>
  );
}
