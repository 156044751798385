import { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import http from "utils/http";
import { AuthApi } from "api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const initialState = {
    firstTime: true,
    cellphone: "",
    code: "",
    accessTokenApp: "",
    accessTokenRefresh: "",
    firebase_id: "",
    logged: false,
    new_cellphone: "",
  };

  const [state, setState] = useState(() => {
    const state = JSON.parse(localStorage.getItem("authContext"));

    if (state === null) {
      return initialState;
    }

    return state;
  });

  useEffect(() => {
    localStorage.setItem("authContext", JSON.stringify(state));
  }, [state]);

  const resetState = () => {
    setState(initialState);
  };

  const login = ({ cellphone, service }) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.post("/auth/send", {
            phone: cellphone,
            country: "",
            ...(service && { service }),
          });

          if (response.data.success) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const codeVerification = async ({ cellphone, code }) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.post("/auth/verify-app", {
            code,
            phone: cellphone,
            country: "",
            test: false,
          });

          if (response.data.success) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const userVerification = async () => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { data } = await AuthApi.getUserFirebaseID();
          const response = await http.get(
            `/users/firebase/${data.data.firebase_id}`,
          );

          if (response.data.success === true) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const sendEmailChangePhone = (email, ssn) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.post("/change-number", {
            email,
            ssn,
          });

          if (response.data.success) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const checkChangePhone = (token) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.get(`/change-number-check/${token}`);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const verifyCodeChangePhone = ({ code, cellphone, tokenNewPhone }) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.put("/change-number", {
            code,
            cellphone,
            tokenNewPhone,
            country: "",
          });

          if (response.data.success) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const sendCodeChangePhone = (phone) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.post("/change-number-code", {
            phone: phone.cellphone,
            service: phone.service,
          });

          if (response.data.success) {
            resolve(response);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      })();
    });
  };

  const fingerprintUtmCheckVisitor = (visitor_id) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await http.get(`/fingerprint_utm/${visitor_id}`);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })();
    });
  };
  const fingerprintUtmUpdate = async ({ visitor_id, user_id }) => {
    const response = await http.put(`/fingerprint_utm`, {
      user_id,
      visitor_id,
    });

    return response;
  };

  const providerValues = {
    /** State */
    state,
    setState,
    resetState,

    /** Handlers */
    login,
    codeVerification,
    userVerification,
    sendEmailChangePhone,
    checkChangePhone,
    sendCodeChangePhone,
    verifyCodeChangePhone,
    fingerprintUtmCheckVisitor,
    fingerprintUtmUpdate,
  };

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
};
