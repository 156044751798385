import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SuccessMoneySVG from "assets/onboarding_v3/success_money.svg";
import Advisement from "components/Advisement";

export default function ActiveLoanSuccessPaymment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount = null, paymentMethodACH = null } = location?.state || {};

    const onContinue = () => {
        navigate(location.state?.onBackward ? "/" : "/active-loan/disbursement")
    }

  return (
    <Fragment>
      <div className="flex flex-col justify-center min-h-full text-center m-auto">
        <img className="mx-auto mb-8" src={SuccessMoneySVG} />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Pago realizado exitosamente
        </h1>
        <p>
          {amount && (
            <span>
              Realizaste un pago por un monto de{" "}
              <span className="font-semibold">${amount}.</span>{" "}
            </span>
          )}
          Te enviaremos un correo con la confirmación y los detalles de tu pago.
        </p>
      </div>

      {paymentMethodACH && (
        <Advisement className="mt-10">
          Puede tardar hasta <strong>3 días laborables</strong> para que su pago
          se refleje en su cuenta de banco.
        </Advisement>
      )}

      <button
        className="bg-blue-kiwi text-white rounded-xl p-3 mt-6"
        onClick={onContinue}
      >
        Continuar
      </button>
    </Fragment>
  );
}
