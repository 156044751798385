import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SuccessMoneySVG from "assets/onboarding_v3/success_money.svg";

export default function KiwiPayActivePaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  const onContinue = () => {
    navigate(location.state?.onBackward ? "/" : "/kiwi-pay/active");
  };

  return (
    <>
      <div className="flex flex-col justify-center min-h-full text-center m-auto">
        <img
          className="mx-auto mb-8"
          src={SuccessMoneySVG}
          alt="Success Money Kiwi"
        />
        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
          Pago realizado exitosamente
        </h1>
        <p>Se ha realizado tu pago exitosamente</p>
      </div>

      <button
        className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
        onClick={onContinue}
      >
        Continuar
      </button>
    </>
  );
}
