import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import MapImage from "assets/cashback/map.png";
import MarkerImage from "assets/cashback/marker.png";
import StoreImage from "assets/cashback/store.svg";
import DollarImage from "assets/cashback/dollar.svg";
import InformationImage from "assets/cashback/information.svg";
import ClockImage from "assets/cashback/time.svg";

import BackwardButton from "components/Button/Backward";
import Button from "components/Button";
import ToggleInput from "components/ToggleInput";
import CancelClaimOffer from "components/Modals/Cashback/CancelClaimOffer";
import LoadingModal from "components/Modals/Loading";

import { getOpeningClosingTimes } from "utils/formatOpeningClosingTimes";

import { CashbackContext } from "context/CashbackContext";

import { CashbackAPI } from "api";
import PaymentAPI from "api/Payment";
import defaultBank from "assets/cashback/bank-default.svg";
import { PlaidAPI } from "api";
import { dataBanks } from "constants";
export default function OfferDetails() {
  const { state: cashbackState, setState: setCashbackState } =
    useContext(CashbackContext);

  const location = useLocation();

  const [offerDetails, setOfferDetails] = useState(cashbackState.selectedOffer);
  const [isOpen, setIsOpen] = useState(false);
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [distance, setDistance] = useState(0);
  const [isOfferPending, setIsOfferPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claimId, setClaimId] = useState(null);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankLogo, setBankLogo] = useState(defaultBank);

  const navigate = useNavigate();

  useEffect(() => {
    if (cashbackState.selectedOffer === null) {
      navigate("/cashback/list-offers");
    }

    checkOfferProcessingStatus();
    setOfferDetails(cashbackState.selectedOffer);
    initOfferDetails();

    calculateDistance(
      {
        latitude: cashbackState.selectedOffer.store.latitude,
        longitude: cashbackState.selectedOffer.store.longitude,
      },
      {
        latitude: cashbackState.user_current_location.lat,
        longitude: cashbackState.user_current_location.lng,
      },
    );
  }, []);

  const initOfferDetails = () => {
    const { isOpen, openingTime, closingTime } = getOpeningClosingTimes(
      cashbackState.selectedOffer,
    );
    setIsOpen(isOpen);
    setOpeningTime(openingTime);
    setClosingTime(closingTime);
  };

  const calculateDistance = async (offerLocation, userLocation) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const R = 6371;
    const dLat = toRadians(userLocation.latitude - offerLocation.latitude);
    const dLng = toRadians(userLocation.longitude - offerLocation.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(offerLocation.latitude)) *
        Math.cos(toRadians(userLocation.latitude)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    setDistance(distance);
  };

  const onBackward = () => {
    setCashbackState((prevState) => ({
      ...prevState,
      selectedOffer: null,
    }));
    if (location.state?.from?.includes("/map-offers")) {
      navigate("/cashback/map-offers");
    } else {
      navigate("/cashback/list-offers");
    }
  };
  const getAccounts = async () => {
    try {
      const { data, success } = await PlaidAPI.checkBankConnection();
      if (!success) throw data;
      return data;
    } catch (error) {
      console.error("Error checking bank connection:", error);
    } finally {
      setLoading(false);
    }
  };
  const onContinue = async () => {
    setLoading(true);
    const responseGetAccounts = await getAccounts();
    try {
      if (!responseGetAccounts) {
        navigate("/cashback/activate");
        return;
      }

      if (
        responseGetAccounts.message === "USER_DONT_HAVE_PLAID_TOKEN" ||
        responseGetAccounts.status !== "connected"
      ) {
        navigate("/cashback/activate");
        return;
      }

      const {
        data: { data: plaidAccounts },
      } = await PaymentAPI.getPlaidAccounts();

      if (!plaidAccounts || plaidAccounts.length === 0) {
        navigate("/cashback/activate");
        return;
      }

      setCashbackState((prevState) => ({
        ...prevState,
        payment_method: plaidAccounts[0],
      }));

      navigate("/cashback/current-bank", {
        state: {
          current_bank: {
            name: responseGetAccounts?.institution_name || "",
            institution_id: responseGetAccounts?.institution_id || "",
          },
          accounts: plaidAccounts[0],
        },
      });
    } catch (error) {
      console.error("Error processing bank accounts:", error);
      navigate("/cashback/activate");
    } finally {
      setLoading(false);
    }
  };

  const onCancelOffer = () => {
    setShowModalCancel(!showModalCancel);
  };

  const handleCancelOffer = async () => {
    setShowModalCancel(false);
  };

  const cancelClaimOffer = async () => {
    try {
      const { data } = await CashbackAPI.cancelClaim(claimId);

      if (data.success) {
        return navigate("/cashback/list-offers");
      } else {
        checkOfferProcessingStatus();
        setShowModalCancel(false);
      }
    } catch (error) {
      console.error("Error canceling claim:", error);
      checkOfferProcessingStatus();
      setShowModalCancel(false);
      return navigate("/cashback/list-offers");
    }
  };

  const checkOfferProcessingStatus = async () => {
    try {
      setLoading(true);
      const { data } = await CashbackAPI.checkOfferProcessingStatus(
        cashbackState.cashback_id,
        offerDetails.id,
      );

      const isPending = data?.is_pending || false;
      setIsOfferPending(isPending);

      if (data?.processed_offer) {
        setClaimId(data.processed_offer.id);
        setExpiredDate(data.processed_offer.expiration_date);
        const bankLogo = dataBanks.find(
          (bank) =>
            bank.institution_id ===
            data.processed_offer.payment_method.current_bank.institution_id,
        )?.image;

        setBankLogo(bankLogo);
        setBankName(data.processed_offer.payment_method.current_bank.name);
      }
    } catch (error) {
      console.error("Error checking offer status:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <CancelClaimOffer
        isOpen={showModalCancel}
        onClose={handleCancelOffer}
        cancelOffer={cancelClaimOffer}
      />
      <LoadingModal isVisible={loading} />
      <div className="w-full min-h-full flex flex-col px-6 py-10">
        <BackwardButton onBackward={onBackward} />
        <div className="flex flex-col gap-6 w-full mb-6">
          <div className="flex justify-between items-center gap-2">
            <img
              src={offerDetails?.store?.merchant?.logo_url}
              className="w-14 h-14 rounded-full aspect-square object-contain"
              alt={offerDetails?.store?.merchant?.merchant_name}
            />
            {isOfferPending && (
              <div className="bg-amber-100-kiwi text-amber-600-kiwi text-xs rounded-full py-1 px-3 font-medium">
                PENDIENTE
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-semibold text-slate-900-kiwi">
              {offerDetails?.store?.merchant?.discount_percentage}% de cashback{" "}
              {offerDetails?.store?.merchant?.merchant_name}
            </h2>
            <div className="flex flex-col items-start w-full">
              <p className="text-sm text-slate-700-kiwi line-clamp-1">
                {offerDetails?.store?.address}
              </p>
              <p className="text-sm text-slate-700-kiwi">
                <span
                  className={`text-${isOpen ? "algae-kiwi-400" : "red-kiwi"} text-sm font-normal`}
                >
                  {isOpen ? "Abierto" : "Cerrado"}
                </span>
                {isOpen
                  ? ` ⋅ Cierra a las ${closingTime}`
                  : ` ⋅ Abre a las ${openingTime}`}
              </p>
            </div>
          </div>
          {isOfferPending && (
            <>
              <div className="bg-regal-blue-kiwi p-4 rounded-xl flex gap-4 h-[74px] items-center">
                <div className="bg-regal-blue-kiwi-800 w-10 h-10 rounded-full flex items-center justify-center">
                  <img className="w-6 h-6" src={ClockImage} />
                </div>
                <div className="flex flex-col text-left">
                  <p className="text-xs text-white/80">
                    Realiza tu compra antes de que expire:
                  </p>
                  <div className="flex items-center gap-2">
                    <p className="text-white text-base font-semibold">
                      {dayjs(expiredDate)
                        .format("MMMM DD, YYYY")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </p>
                  </div>
                </div>
              </div>
              {claimId && (
                <div className="flex flex-col items-start gap-4 w-full">
                  <p className="text-sm text-slate-900-kiwi font-semibold">
                    Banco de pago seleccionado
                  </p>
                  <div className="bg-slate-50-kiwi rounded-lg p-4 w-full flex items-center gap-4">
                    {bankLogo ? (
                      <img
                        src={bankLogo}
                        className="w-10 h-10 object-contain rounded-full bg-white"
                      />
                    ) : (
                      <img
                        src={defaultBank}
                        className="w-10 h-10 object-contain rounded-full bg-white"
                      />
                    )}
                    <p className="text-dark-kiwi font-semibold">{bankName}</p>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="flex flex-col items-start gap-2 w-full">
            <p className="text-sm text-slate-900-kiwi font-semibold">
              Ubicación
            </p>
            <a
              href={`https://www.google.com/maps/@${offerDetails.store.latitude},${offerDetails.store.longitude},20z`}
              target="_blank"
              rel="noreferrer"
              className="border border-slate-200-kiwi rounded-xl p-2 w-full flex items-center gap-2"
            >
              <div className="relative flex items-center justify-center min-w-[6rem]">
                <img src={MapImage} className="h-14" />
                <img src={MarkerImage} className="h-6 absolute" />
              </div>
              <div className="flex flex-col gap-2 w-max">
                <p className="line-clamp-1 text-xs font-semibold text-slate-900-kiwi">
                  {offerDetails?.store?.address}
                </p>
                <p className="text-xs text-slate-400-kiwi">
                  {distance.toFixed(2)} km
                </p>
              </div>
            </a>
          </div>
          <ToggleInput title="¿Cómo obtener el cashback?" saveState={false}>
            <div className="flex flex-col gap-4">
              <div className="flex items-start gap-4">
                <div className="bg-slate-100-kiwi w-max min-w-max flex items-center justify-center p-2 rounded-full">
                  <img src={StoreImage} />
                </div>
                <p className="text-slate-700-kiwi text-sm">
                  <span className="font-semibold">Realiza tu compra</span> antes
                  de la fecha de vencimiento de la oferta.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-slate-100-kiwi w-max min-w-max flex items-center justify-center p-2 rounded-full">
                  <img src={DollarImage} />
                </div>
                <p className="text-slate-700-kiwi text-sm">
                  <span className="font-semibold">Te notificaremos</span> cuando
                  tu cashback esté disponible.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <div className="bg-slate-100-kiwi w-max min-w-max flex items-center justify-center p-2 rounded-full">
                  <img src={InformationImage} />
                </div>
                <p className="text-slate-700-kiwi text-sm">
                  <span className="font-semibold">Tope máximo</span> del reclamo
                  ${offerDetails.store.merchant.max_discount}.
                </p>
              </div>
            </div>
          </ToggleInput>
        </div>

        <Button
          onClick={isOfferPending ? onCancelOffer : onContinue}
          loading={loading}
          className={`${
            isOfferPending
              ? "!border-blue-kiwi border !bg-white !text-blue-kiwi"
              : "bg-blue-kiwi text-white"
          } rounded-2xl p-3 mt-auto w-full`}
        >
          {isOfferPending ? "Cancelar reclamo" : "Reclamar oferta"}
        </Button>
      </div>
    </>
  );
}
