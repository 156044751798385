import React from "react";
import { Outlet } from "react-router-dom";

export default function SelfPayment() {
  return (
    <div className="flex flex-col w-full min-h-full px-6 pb-10 pt-10">
      <Outlet />
    </div>
  );
}
