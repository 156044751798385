import http from "utils/http";

export const getUserFirebaseID = async () => {
  const response = await http.get("/auth/firebase-id");
  return response;
};

export const newAccessToken = async (refresh_token) => {
  const response = await http.post("/auth/new-access-token", undefined, {
    headers: {
      KiwiRenew: refresh_token,
    },
  });
  return response;
};

export default {
  getUserFirebaseID,
  newAccessToken,
};
