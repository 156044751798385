import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleRedirect } from "./RedirectService";
import { AuthContext } from "context/AuthContext";
import { UserContext } from "context/UserContext";
import SplashComponent from "components/Splash";

const RedirectHandler = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    state: { logged },
    userVerification,
  } = useContext(AuthContext);
  const {
    state: { user },
  } = useContext(UserContext);

  const [isProcessing, setIsProcessing] = useState(true);

  const processRedirection = useCallback(async () => {
    const targetFlow = searchParams.get("flow");
    const targetUserId = searchParams.get("userId");
    const source = searchParams.get("source");

    // Validar que el source sea válido
    if (source && !["email", "landing"].includes(source)) {
      console.error("Invalid source parameter:", source);
      navigate("/error", { replace: true });
      return;
    }

    // Si no hay flow, no hay nada que procesar
    if (!targetFlow) {
      setIsProcessing(false);
      return;
    }

    // Si es email y no tiene userId, error
    if (source === "email" && !targetUserId) {
      console.error("Email source requires userId parameter");
      navigate("/error", { replace: true });
      return;
    }

    try {
      let dataUser = user;
      if (logged) {
        const { data } = await userVerification();
        dataUser = data.user;
      }

      const { path, shouldRedirect } = await handleRedirect(
        targetFlow,
        targetUserId,
        { user: dataUser, logged },
        source,
      );

      if (shouldRedirect) {
        navigate(path, { replace: true });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error during redirect:", error);
      navigate("/error", { replace: true });
    } finally {
      setIsProcessing(false);
    }
  }, [navigate, searchParams, logged, user, userVerification]);

  useEffect(() => {
    processRedirection();
  }, [processRedirection]);

  if (isProcessing) {
    return <SplashComponent />;
  }

  return null;
};

export default RedirectHandler;
