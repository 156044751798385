import { Route } from "react-router-dom";

/**
 * Context Providers
 */


/**
 * Routes
 */
import SelfPayment from "views/mobile/SelfPayment";
import SelfPaymentForm from "views/mobile/SelfPayment/SelfPaymentForm";

const SelfPaymentRoutes = (
  <Route
    exact
    path="self-payment"
    element={
        <SelfPayment />
    }
  >
    <Route exact path="" element={<SelfPaymentForm />} />
  </Route>
);

export default SelfPaymentRoutes;
