import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DownIcon, UpIcon } from "assets/icons_v3/fonts";
import tailwindConfig from "utils/tailwindConfig";
import formatDate from "utils/formatDate";

export default function SelectProducts({
  products,
  onSelectProduct
}) {
  const cardContent = useRef();
  const cardHeader = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    onExpandCard(!isOpen);
  }, []);

  const onExpandCard = (isOpen) => {
    if (isOpen) {
      cardContent.current.style.display = "none";
      cardHeader.current.style.backgroundColor = "transparent";
      cardHeader.current.style.border =
        "1px solid " + tailwindConfig.theme.extend.colors["slate-200-kiwi"];
    } else {
      cardContent.current.style.display = "block";
      cardHeader.current.style.backgroundColor =
        tailwindConfig.theme.extend.colors["blue-50-kiwi"];
      cardHeader.current.style.border = "1px solid transparent";
    }
  };

  const onToggleCard = () => {
    onExpandCard(isOpen);
    setIsOpen(!isOpen);
  };

  const onClickProduct = (product) => {
    setSelectedProduct(product);
    onToggleCard();
    onSelectProduct(product);
  }

  return (
    <div className={`bg-white w-full relative`}>
      <div
        ref={cardHeader}
        onClick={onToggleCard}
        className="flex items-center p-4 bg-blue-50-kiwi rounded-xl cursor-pointer relative z-10"
      >
        {selectedProduct && <img src={selectedProduct?.img} className="w-6 mr-4" />}
        <div className="text-md mr-auto">
          {selectedProduct?.title || "Seleccionar producto"}
        </div>
        {isOpen ? <UpIcon /> : <DownIcon />}
      </div>
      <div
        className="max-h-max absolute border border-slate-200-kiwi shadow-md rounded-xl mt-1 z-20 bg-white w-full"
        ref={cardContent}
      >
        {products.map((product, index) => (
          <div
            key={product.title}
            className={`flex items-center p-4 cursor-pointer ${product.length === index + 1 ? "" : "border-b"} border-slate-200-kiwi hover:bg-slate-50-kiwi ${selectedProduct?.title === product.title ? "bg-slate-50-kiwi" : ""}`}
            onClick={() => onClickProduct(product)}
          >
            <div className="w-12 h-12 rounded-full bg-blue-kiwi/10 flex items-center justify-center">
              <img src={product.img} className="w-6" />
            </div>

            <div className="text-sm ml-4">
              <div className="font-medium text-regal-blue-kiwi">
                {product.title}
              </div>
              <div className="text-slate-500-kiwi text-xs">
                Fecha pago:{" "}
                {formatDate(product.nextPaymentDate, "MMMM DD, YYYY")}
              </div>
            </div>
            <div className="font-semibold text-md ml-auto text-slate-500-kiwi">
              ${product.nextPaymentAmount}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

SelectProducts.propTypes = {
  products: PropTypes.array.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
};
