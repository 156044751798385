import { useEffect } from "react";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { CashbackAPI } from "api";
import { UserContext } from "context/UserContext";
import { CashbackContext } from "context/CashbackContext";
import useNotificationsCenter from "hooks/useNotificationsCenter";
import PlaidDisconnect from "./PlaidDisconnect";

export default function Cashback() {
  const {
    state: { user },
  } = useContext(UserContext);
  const { setState: setCashbackState } = useContext(CashbackContext);
  const notifications = useNotificationsCenter();

  const alertDisconnectPlaid = notifications.some(
    (notification) => notification?.reason === "BANK_ACCOUNT_IS_INACTIVE",
  );

  const getCashbackId = async () => {
    try {
      const { data: existingCashback } = await CashbackAPI.getCashbackId(
        user.id,
      );

      if (!existingCashback) {
        const { data: newCashback } = await CashbackAPI.createCashbackId(
          user.id,
          0,
        );
        setCashbackState((prev) => ({
          ...prev,
          cashback_id: newCashback.id,
        }));
        return;
      }
      setCashbackState((prev) => ({
        ...prev,
        cashback_id: existingCashback.id,
      }));
    } catch (error) {
      console.error("Error getting cashback details:", error);
    }
  };

  useEffect(() => {
    getCashbackId();
  }, []);

  return alertDisconnectPlaid ? <PlaidDisconnect /> : <Outlet />;
}
