import { usePlaidConnection } from "hooks/usePlaidConnection";
import BankErrorSVG from "assets/cashback/bank_error.svg";
import LoadingModal from "components/Modals/Loading";
import Button from "components/Button";
import Menu from "templates/Menu";

const PlaidDisconnect = () => {
  const { loading, showLoader, startPlaidConnection } = usePlaidConnection({
    redirectPath: "/bank-accounts/success",
    redirectState: {
      textButton: "Ir a cashback",
      redirectRouteButton: "/cashback/welcome",
    },
  });

  return (
    <>
      <LoadingModal isVisible={showLoader} />
      <div className="flex flex-col w-full min-h-full px-6 py-10">
        <div className="flex flex-col justify-center gap-8 pt-12 my-auto">
          <img src={BankErrorSVG} className="max-w-[13rem] mx-auto" />
          <div className="flex flex-col gap-4 text-center">
            <h1 className="text-slate-900-kiwi font-semibold text-2xl">
              Tu cuenta bancaria se ha desconectado
            </h1>
            <p className="text-gray-700-kiwi">
              Para seguir disfrutando de tu cashback y otras funcionalidades,
              conecta nuevamente tu cuenta bancaria. Esto permitirá procesar tus
              recompensas sin inconvenientes.
            </p>
          </div>
        </div>
        <Button
          className="rounded-xl p-3 mt-auto mb-16"
          loading={loading}
          onClick={startPlaidConnection}
        >
          Conectar cuenta de banco
        </Button>
      </div>
      <Menu selected="cashback" />
    </>
  );
};

export default PlaidDisconnect;
