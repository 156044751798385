import { UserContext } from "context/UserContext";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { User3Icon } from "assets/icons_v3/fonts";
import useNotificationsCenter from "hooks/useNotificationsCenter";

export default function HomeHeader({ className }) {
  const notifications = useNotificationsCenter();
  const [hasNotifications, setHasNotifications] = useState(false);

  useEffect(() => {
    setHasNotifications(
      notifications.find((item) => item.sections.includes("settings")),
    );
  }, [notifications]);

  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const { user } = userState;

  const onReferralCode = () => {
    navigate("/settings");
  };

  return (
    <div className={`flex items-center gap-6 justify-between ${className}`}>
      <div>
        <div className="text-sm text-slate-500-kiwi">Hola,</div>
        <div className="font-semibold -my-1 text-xl text-slate-900-kiwi">
          {user.first_name}
        </div>
      </div>
      <div className="flex gap-2">
        <div
          className="w-9 h-9 bg-gray-200-kiwi rounded-full flex relative cursor-pointer"
          onClick={onReferralCode}
        >
          <User3Icon className="w-5 m-auto" />

          {hasNotifications && (
            <div className="w-2 h-2 bg-red-500 rounded-full absolute right-0"></div>
          )}
        </div>
      </div>
    </div>
  );
}

HomeHeader.propTypes = {
  className: PropTypes.string,
};
