import { UserContext } from 'context/UserContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightUpIcon, InformationIcon } from 'assets/icons_v3/fonts';
import ShopBagSVG from 'assets/icons_v3/shop_bag.svg';
import formatDateCapitalize from 'utils/formatDateCapitalize';
import ToggleProduct from './ToggleProduct';
import moneyFormat from 'utils/moneyFormat';

export default function HomeStatusPersonalLoan() {
    const navigate = useNavigate();

    const { state: userState } = useContext(UserContext);
    const {
        products: {
            kiwi_pay: {
                loan: { daysPastDue, balance, nextPaymentDate, amountDue, nextPaymentAmount },
            },
        },
    } = userState;

    const isLoanDue = daysPastDue > 0;
    const isLoanPaid = balance === 0;

    const onSelect = () => {
        if (isLoanDue) {
            return navigate('/kiwi-pay/active/pay-fee');
        }

        navigate('/kiwi-pay/active');
    };

    return (
        <ToggleProduct img={ShopBagSVG} title="KiwiPay">
            <div className="p-4 cursor-pointer" onClick={onSelect}>
                <div className="flex items-start justify-between gap-4">
                    <div>
                        <div className="text-sm text-regal-blue-kiwi/50">
                            Próximo pago
                        </div>
                        <div className="font-semibold text-3xl text-regal-blue-kiwi">
                            ${moneyFormat(nextPaymentAmount)}
                        </div>
                    </div>

                    <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                        Pagar{' '}
                        <ArrowRightUpIcon className="w-4 h-4 text-white" />
                    </div>
                </div>

          {!isLoanDue && !isLoanPaid && (
            <div className="text-xs text-regal-blue-kiwi/50 mt-4">
              Fecha de pago:{" "}
              <span className="font-medium">
                {formatDateCapitalize(nextPaymentDate)}
              </span>
            </div>
          )}
        </div>

            {isLoanDue && (
                <div
                    className="flex items-center font-semibold text-xs bg-yellow-50-kiwi text-yellow-900-kiwi py-2 px-4 rounded-br-xl rounded-bl-xl"
                    onClick={onSelect}>
                    <InformationIcon className="w-4 mr-2" />
                    Retraso de {daysPastDue} {daysPastDue > 1 ? 'días' : 'día'}{' '}
                    en tu pago | ${amountDue}
                </div>
            )}
        </ToggleProduct>
    );
}
