import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import CustomPaymentModal from "components/Modals/ChangeAmountPay";
import { CurrencyDollarIcon } from "assets/icons_v3/fonts";
import KiwiLogo from "assets/logo.svg";
import SelectProducts from "components/SelectProducts";
import MoneySVG from "assets/icons_v3/money.svg";
import ShopBagSVG from "assets/icons_v3/shop_bag.svg";
import ChartLineSVG from "assets/icons_v3/chart_line.svg";
import PropTypes from "prop-types";

dayjs.extend(isToday);

const productsSelectMap = {
  "personal_loan": {
    "title": "Préstamo personal",
    "img": MoneySVG,
    "redirect": "/active-loan/make-payment"
  },
  "credit_builder": {
    "title": "Credit Builder",
    "img": ChartLineSVG,
    "redirect": "/credit-builder/active/make-payment"
  },
  "kiwi_pay": {
    "title": "Kiwi Pay",
    "img": ShopBagSVG,
    "redirect": "/kiwi-pay/active/make-payment"
  }
}

const amountOptions = {
  PAY_FEE: "onPayFee",
  PAY_REMAINING_BALANCE: "onPayRemainingBalance",
  PAY_CUSTOM_PAYMENT: "onPayCustomPayment"
}

const AmountOption = ({
  title,
  description,
  amount,
  onClick,
  isSelected,
  itemId
}) => {
  return (
    <div className="flex items-center rounded-lg p-4 cursor-pointer border border-gray-200-kiwi relative mb-4" onClick={onClick}>
      <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex">
        <CurrencyDollarIcon className="w-6 m-auto text-regal-blue-kiwi" />
      </div>
      <div className="mr-auto">
        <div className="leading-4 font-medium mb-1 text-regal-blue-kiwi">
          {title}
        </div>
        <p className="text-xs text-gray-500-kiwi">{description}</p>
      </div>
      <div className="mr-8 font-semibold text-slate-500-kiwi">${amount}</div>
      <input
        className="peer hidden"
        type="radio"
        id={itemId}
        name="amount-options"
        readOnly
        checked={isSelected}
      />
      <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
    </div>
  );
}

AmountOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired
};

export default function SelfPaymentForm() {
  const navigate = useNavigate();
  const [visibleCustomPaymentModal, setVisibleCustomPaymentModal] =
    useState(false);
  const [userProducts, setUserProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAmountOption, setSelectedAmountOption] = useState(null);

  const { state: userState } = useContext(UserContext);
  const { user } = userState;

  useEffect(() => {
    setProducts();
  }, []);


  const onPayCustomPayment = () => {
    if (!selectedProduct) return;
    setVisibleCustomPaymentModal(true);
  };

  const onChangeCustomPayment = (amount) => {
    onMakePayment(amount);
  };

  const onPayRemainingBalance = () => {
    if (!selectedProduct) return;
    onMakePayment(selectedProduct.balance);
  };

  const onPayFee = () => {
    if(!selectedProduct) return;
    onMakePayment(selectedProduct.paymentFee);
  };

  const onMakePayment = (paymentAmount) => {
    navigate(selectedProduct.redirect, {
      state: {
        paymentAmount,
        onBackward: "/self-payment",
      },
    });
  };

  const setProducts = () => {
    const products = [];
    Object.keys(productsSelectMap).forEach((product) => {
      if(userState.products[product].loan?.nextPaymentDate) {
        const loan = userState.products[product].loan;
        products.push({
          ...productsSelectMap[product],
          ...loan,
          loanHasDue: loan.daysPastDue > 0,
          paymentFee: Number(loan.daysPastDue > 0 ? loan.amountDue : loan.nextPaymentAmount),
          remainingPayments: loan.term - loan.payments.filter((payment) => payment.status === "Active").length,
        });
      }
    })
    setUserProducts(products);
  }

  const onSelectProduct = (product) => {
    setSelectedProduct(product);
  }

  const onSelectAmountOption = (option) => {
    if(!selectedProduct) return;
    setSelectedAmountOption(option);
  }

  const onContinue = () => {
    if(!selectedAmountOption) return;
    if(selectedAmountOption === amountOptions.PAY_CUSTOM_PAYMENT) {
      onPayCustomPayment();
    } else if (selectedAmountOption === amountOptions.PAY_FEE) {
      onPayFee();
    } else {
      onPayRemainingBalance();
    }
  }

  return (
    <>
      <img
        id="logo_kiwi"
        width="110"
        className="mb-5 self-start"
        src={KiwiLogo}
      />
      <div className="font-semibold text-xl text-slate-900-kiwi mb-10 -mt-1">
        Hola, {user.first_name}
      </div>

      <h2 className="text-dark-kiwi mb-4 font-medium">
        1. ¿Qué producto quieres pagar?
      </h2>

      <SelectProducts
        products={userProducts}
        onSelectProduct={onSelectProduct}
      />

      <h2 className="text-dark-kiwi mb-4 mt-12 font-medium">
        2. ¿Cuánto quieres pagar?
      </h2>

      <AmountOption
        itemId={amountOptions.PAY_FEE}
        title={selectedProduct ? (selectedProduct.loanHasDue ? "Pago deuda" : "Pago cuota") : "Pago cuota"}
        description="Balance pendiente"
        amount={selectedProduct ? selectedProduct.paymentFee.toString() : "0.00"}
        onClick={() => onSelectAmountOption(amountOptions.PAY_FEE)}
        isSelected={selectedAmountOption === amountOptions.PAY_FEE} />

      <AmountOption
        itemId={amountOptions.PAY_REMAINING_BALANCE}
        title="Saldo total"
        description="Balance completo"
        amount={selectedProduct ? selectedProduct.balance.toString() : "0.00"}
        onClick={() => onSelectAmountOption(amountOptions.PAY_REMAINING_BALANCE)}
        isSelected={selectedAmountOption === amountOptions.PAY_REMAINING_BALANCE}
      />

      <AmountOption
        itemId={amountOptions.PAY_CUSTOM_PAYMENT}
        title="Monto diferente"
        description="Valor personalizado"
        amount="0.00"
        onClick={() => onSelectAmountOption(amountOptions.PAY_CUSTOM_PAYMENT)}
        isSelected={selectedAmountOption === amountOptions.PAY_CUSTOM_PAYMENT}
      />

      <button
        className="bg-blue-kiwi text-white rounded-xl p-3 w-full mt-auto disabled:bg-gray-400-kiwi disabled:text-white"
        onClick={onContinue}
        disabled={!selectedAmountOption}
      >
        Continuar
      </button>

      <CustomPaymentModal
        minAmount={0}
        maxAmount={selectedProduct ? selectedProduct.balance : 0}
        sendAmount={onChangeCustomPayment}
        isVisible={visibleCustomPaymentModal}
        closeModal={() => setVisibleCustomPaymentModal(false)}
      />
    </>
  );
}
