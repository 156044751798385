import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { CashbackContext } from "context/CashbackContext";
import { UserContext } from "context/UserContext";

import { CashbackAPI } from "api";
import { CASHBACK_TRANSACTION_STATUS } from "constants";

import LoadingModal from "components/Modals/Loading";
import AlertPendingOffer from "components/Modals/AlertPendingOffer";
import AlertClaimCadenceOffer from "components/Modals/AlertClaimCadenceOffer";

import CashbackOfferComponent from "components/CashbackOffer";
import MenuCashbackComponent from "components/MenuCashback";
import Menu from "templates/Menu";
import SkeletonCardCashback from "components/SkeletonCardCashback";
import CashbackPendingSliderComponent from "components/CashbackPendingSlider";

import LocationIcon from "assets/cashback/location_line.svg";
import EmptyState from "assets/cashback/empty_state.svg";
import { MapLineIcon, BillIcon } from "assets/icons_v3/fonts";
export default function CashbackListOffers() {
  const navigate = useNavigate();
  const { state: cashbackContext, setState: setCashbackState } =
    useContext(CashbackContext);
  const { state: userContext } = useContext(UserContext);

  const [offers, setOffers] = useState([]);
  const [processedOffersPending, setProcessedOffersPending] = useState([]);
  const [offerSelected, setOfferSelected] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [city, setCity] = useState("");

  const [isActiveMap, setIsActiveMap] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showAlertPendingOffer, setShowAlertPendingOffer] = useState(false);
  const [showAlertClaimCadenceOffer, setShowAlertClaimCadenceOffer] =
    useState(false);

  const scrollRef = useRef(null);

  const getOffers = async (value) => {
    try {
      setLoading(true);
      const { data } = await CashbackAPI.getOffersNearByUser(
        userContext.user.id,
        {
          latitude: cashbackContext.address.latitude,
          longitude: cashbackContext.address.longitude,
        },
      );
      if (data.length === 0) {
        setOffers([]);
        return;
      }

      if (value !== "all") {
        const filtered = data.filter(
          (offer) => offer.store.merchant.industry === value,
        );
        setOffers(filtered);
      } else {
        setOffers(data);
      }
    } catch (error) {
      console.error("Error fetching offers:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getProcessedOffersPending = async () => {
    try {
      const { data } = await CashbackAPI.getProcessedOffersByStatus({
        cashback_id: cashbackContext.cashback_id,
        status: CASHBACK_TRANSACTION_STATUS.PENDING,
      });
      setProcessedOffersPending(data);
    } catch (error) {
      console.error("Error fetching processed offers:", error.message);
    }
  };

  const onSelectOffer = async (offer) => {
    setOfferSelected(offer);

    switch (true) {
      case offer.is_waiting_merchant_cadence:
        setShowAlertClaimCadenceOffer(true);
        return;
      case offer.is_merchant_pending:
        setShowAlertPendingOffer(true);
        return;
      default:
        setCashbackState((state) => ({
          ...state,
          selectedOffer: offer,
        }));
        navigate("/cashback/offer-details");
        break;
    }
  };

  const onClickShowMap = () => {
    navigate("/cashback/map-offers");
  };

  const onClickHistory = () => {
    navigate("/cashback/history");
  };

  const selectOption = async (value) => {
    getOffers(value);
    setSelectedCategory(value);
  };

  const handleScroll = () => {
    if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const isBottom = scrollTop + clientHeight >= scrollHeight;
    setIsActiveMap(!isBottom);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [shouldShowImage, setShouldShowImage] = useState(true);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
    validateImageVisibility();
  };

  const validateImageVisibility = () => {
    if (!scrollRef.current) return;

    const { clientHeight } = scrollRef.current;
    const isContentLargerThanScreen = clientHeight <= 740;

    setShouldShowImage(!isContentLargerThanScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    validateImageVisibility();
  }, [windowHeight, offers]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getCity = () => {
    const { city, street } = cashbackContext.address;
    setCity(city || street);
  };

  const redirectToPendingOffers = () => {
    navigate("/cashback/pending-offers");
  };

  useEffect(() => {
    getCity();
    getProcessedOffersPending();
    getOffers("all");
  }, []);

  return (
    <>
      <LoadingModal isVisible={loading} />
      <AlertPendingOffer
        logo_url={offerSelected?.store?.merchant?.logo_url ?? ""}
        merchant_name={offerSelected?.store?.merchant?.merchant_name ?? ""}
        isVisible={showAlertPendingOffer}
        closeModal={() => setShowAlertPendingOffer(false)}
      />
      <AlertClaimCadenceOffer
        logo_url={offerSelected?.store?.merchant?.logo_url ?? ""}
        next_claim_date={offerSelected?.next_claim_date ?? ""}
        merchant_name={offerSelected?.store?.merchant?.merchant_name ?? ""}
        isVisible={showAlertClaimCadenceOffer}
        closeModal={() => setShowAlertClaimCadenceOffer(false)}
      />
      <div
        ref={scrollRef}
        className="flex flex-col w-full min-h-full h-full overflow-auto"
      >
        <div className="flex flex-col text-left h-full">
          <div className="fixed top-0 sm:top-6 bg-white pt-10 pb-8 z-10 rounded-t-2xl">
            <div className="flex justify-between px-6">
              <div>
                <h1 className="text-slate-900-kiwi font-semibold text-2xl">
                  Ofertas
                </h1>
                <div className="flex items-center gap-2">
                  <img src={LocationIcon} />
                  <p className="font-medium text-sm text-slate-500">{city}</p>
                </div>
              </div>
              <span
                onClick={onClickHistory}
                className="flex cursor-pointer w-9 h-9 rounded-full bg-gray-100-kiwi text-center justify-center items-center"
              >
                <BillIcon className="m-auto text-slate-900 h-5 w-5" />
              </span>
            </div>
            <div className="w-full block mt-8 max-w-[28.12rem]">
              <MenuCashbackComponent
                selectedCategory={selectedCategory}
                selectOption={selectOption}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 pb-16 h-full pt-48">
            {!loading ? (
              <>
                {processedOffersPending?.[0] && (
                  <>
                    <div className="flex items-center justify-between px-6">
                      <p className="text-sm text-slate-900 font-semibold">
                        Activas
                      </p>
                      {processedOffersPending.length > 1 && (
                        <button
                          onClick={redirectToPendingOffers}
                          className="text-xs text-regal-blue-950-kiwi font-medium"
                        >
                          Ver todos
                        </button>
                      )}
                    </div>
                    <div className="w-full block">
                      <CashbackPendingSliderComponent
                        offersPending={processedOffersPending}
                        onSelectOffer={onSelectOffer}
                      />
                    </div>
                    <p className="text-sm text-slate-900 font-semibold px-6">
                      Disponibles
                    </p>
                  </>
                )}

                {offers?.length > 0 ? (
                  offers?.map((offer, index) => (
                    <div
                      key={offer.id}
                      className={`${index === offers.length - 1 ? "pb-24" : ""} px-6`}
                    >
                      <CashbackOfferComponent
                        showStatus={true}
                        offer={offer}
                        onSelectOffer={() => onSelectOffer(offer)}
                      />
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col items-center mt-4 px-6 overflow-auto">
                    {shouldShowImage && (
                      <img src={EmptyState} className="w-32 h-32" />
                    )}
                    <div className="flex flex-col gap-2 text-center mt-4">
                      <p className="font-semibold text-slate-600">
                        No hay tiendas disponibles en esta ubicación
                      </p>
                      <p className="text-sm text-slate-500">
                        Explora otras zonas en el mapa.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col gap-4 pb-16 h-full px-6">
                {Array.from({ length: 10 }).map((_, index) => (
                  <SkeletonCardCashback key={index} />
                ))}
              </div>
            )}
            <button
              onClick={onClickShowMap}
              className={`fixed justify-center cursor-pointer px-4 py-1.5 bottom-32 left-1/2 -translate-x-1/2 bg-regal-blue-950-kiwi text-white rounded-3xl text-xs flex items-center gap-2 transition-all duration-300 ease-in-out ${isActiveMap ? "opacity-100" : "opacity-0 pointer-events-none"}`}
            >
              <MapLineIcon />
              <p>Ver mapa</p>
            </button>
          </div>
        </div>
        <Menu selected="cashback"></Menu>
      </div>
    </>
  );
}
