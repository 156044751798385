const defaultRedirectPath = "/";

/**
 * Procesa la redirección basada en el estado de autenticación y parámetros
 * @param {string} targetFlow - Flujo objetivo de la redirección
 * @param {string|null} targetUserId - ID del usuario objetivo (opcional)
 * @param {object} currentSession - Información de la sesión actual
 * @param {string} source - Origen de la redirección (email o landing)
 * @returns {object} Información de redirección
 */
export const handleRedirect = async (
  targetFlow,
  targetUserId,
  currentSession,
  source,
) => {
  const isAuthenticated = currentSession?.logged;
  const currentUserId = currentSession?.user?.id;

  // Guardar el flujo objetivo para después del login
  if (targetFlow) {
    sessionStorage.setItem("redirectFlow", targetFlow);
  }

  // Caso 1: Usuario no autenticado
  if (!isAuthenticated) {
    return {
      path: "/login",
      shouldRedirect: true,
    };
  }

  // Caso 2: Usuario autenticado pero diferente al objetivo (solo para source=email)
  if (source === "email" && targetUserId && currentUserId !== targetUserId) {
    await logout();
    return {
      path: "/login",
      shouldRedirect: true,
    };
  }

  // Caso 3: Usuario autenticado y correcto
  return {
    path: targetFlow || defaultRedirectPath,
    shouldRedirect: true,
  };
};

export const logout = async () => {
  // Limpiar la sesión actual
  localStorage.clear();
  sessionStorage.clear();
};
